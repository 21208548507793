import React, { useState,useEffect } from "react"

function PageIntroThreeColumns({ threeColumnsColumn1,threeColumnsColumn2,threeColumnsColumn3 }){
  const pageUrl = typeof window !== 'undefined' ? window.location.pathname : '';
  const [url, setUrl] = useState(pageUrl)
  const [removeTemp, setRemoveTemp] = useState(false)
  const [showHalf, setShowHalf] = useState(false)

  const updateLoad=(e)=>{
    if(url==="/intermediaries/" || url==="/specialist-car-finance-for-individuals/personal-product-range/" || url==="/intermediaries/specialist-car-finance-intermediaries/product-range/"){
      setRemoveTemp(true)
    }
    else{
      setRemoveTemp(false)
    }

    if(threeColumnsColumn1.threeIntroLeftTitle || threeColumnsColumn1.threeIntroLeftIntro){
        setShowHalf(true)
    }else{
      setShowHalf(false)
    }

  }
  useEffect(() => {

    console.log('three columns ', 
      threeColumnsColumn1?.threeIntroLeftButtonLinkPortal, 
      threeColumnsColumn1?.threeIntroLeftButtonLabelPortal, 
    )

    updateLoad()
  }, []);


return (
  <section className="flexible navy-background three-column-layout">
  <div class="eighty-spacer"></div>
  <div className="columns content-block">
    <div className="one-flex-container">
      <div className="third-flex-container staggerFadeIn stag-visible">
          <div className="wrapper">
            {/* <a href={threeColumnsColumn1.threeIntroLeftButtonLink} target="_blank" rel="noopener noreferrer"> */}
              <div className="item">
                <div className="htb-difference-title-holders">
                  {threeColumnsColumn1.threeIntroLeftTitle}
                  <span dangerouslySetInnerHTML={{ __html: threeColumnsColumn1.threeIntroLeftIntro }}/>
                </div>
               
                <div className="htb-login-wrapper">
                   <a className="terms-link white-button navy-text sbs-button-one"  href={threeColumnsColumn1.threeIntroLeftButtonLink} target="_blank" rel="noopener noreferrer">
                      <p>{threeColumnsColumn1?.threeIntroLeftButtonLabel}</p>
                  </a>

                  {threeColumnsColumn1?.threeIntroLeftButtonLinkPortal &&               
                    <a class="htb-login-button-wrapper" target="_blank" rel="noopener noreferrer" href={threeColumnsColumn1?.threeIntroLeftButtonLinkPortal}>
                        {threeColumnsColumn1?.threeIntroLeftButtonLabelPortal &&
                          <span className="text-white" dangerouslySetInnerHTML={{ __html: threeColumnsColumn1?.threeIntroLeftButtonLabelPortal}}/>
                        } 
                        <p class="htb-portal-login-button">
                          Go
                        </p>
                    </a>
                  }

                </div>
              </div>
            {/* </a> */}
            <div className="htb-difference-text-holders">
              <span dangerouslySetInnerHTML={{ __html: threeColumnsColumn1.threeIntroLeftText }}/>
            </div>
          </div>
      </div>
      <div className="third-flex-container staggerFadeIn stag-visible">
          <div className="wrapper">
            <a href={threeColumnsColumn2.threeIntroMiddleButtonLink} target="_blank" rel="noopener noreferrer">
              <div className="item">
                <div className="htb-difference-title-holders">
                  {threeColumnsColumn2.threeIntroMiddleTitle}
                  <span dangerouslySetInnerHTML={{ __html: threeColumnsColumn2.threeIntroMiddleIntro }}/>
                </div>
                  <div class="terms-link white-button navy-text sbs-button-one">
                    <p>{threeColumnsColumn2.threeIntroMiddleButtonLabel}</p>
                  </div>
              </div>
            </a>
            <div className="htb-difference-text-holders">
              <span dangerouslySetInnerHTML={{ __html: threeColumnsColumn2.threeIntroMiddleText }}/>
            </div>
          </div>
      </div>
      <div className="third-flex-container staggerFadeIn stag-visible">
        <div className="wrapper">
          <a href={threeColumnsColumn3.threeIntroRightButtonLink} target="_blank" rel="noopener noreferrer">
            <div className="item">
              <div className="htb-difference-title-holders">
                {threeColumnsColumn3.threeIntroRightTitle}
                <span dangerouslySetInnerHTML={{ __html: threeColumnsColumn3.threeIntroRightIntro }}/>
              </div>
              <div class="terms-link white-button navy-text sbs-button-one">
                <p>{threeColumnsColumn3.threeIntroRightButtonLabel}</p>
              </div>
            </div>
          </a>
          <div className="htb-difference-text-holders">
            <span dangerouslySetInnerHTML={{ __html: threeColumnsColumn3.threeIntroRightText }}/> 
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="eighty-spacer"></div>
</section>
 )
}


export default PageIntroThreeColumns